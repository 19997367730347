import { useRouter } from 'next/router';
import Button from '../components/Button';

export default function Custom404() {
  const router = useRouter();
  return (
    <div className="flex h-full w-full flex-col items-center justify-center space-y-4">
      <h1 className="text-xl font-bold tracking-tight text-gray-700">
        🤔 404 - Page Not Found
      </h1>
      <Button click={() => router.push('/')} size={'small'}>
        Go Home
      </Button>
    </div>
  );
}
